<script setup>
import {__} from "@/Utils/index.js";
import {PageNavigation, ThemeStore} from "@/Stores/index.js";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import {Avatar, UserInfo} from "@/Components/Common/index.js";
import {Link, usePage} from "@inertiajs/vue3";
import EnvironmentBadge from "@/Layouts/Partials/EnvironmentBadge.vue";
import Submenu from "@/Layouts/Partials/Submenu.vue";
import {ref, watch} from "vue";
import {Switch} from "@/Components/Inputs/index.js";
import {MoonIcon} from "@heroicons/vue/24/outline";
import {ArrowRightStartOnRectangleIcon, QuestionMarkCircleIcon} from "@heroicons/vue/20/solid/index.js";
import TimeCounter from "@/Components/Common/TimeCounter.vue";
import {route} from "ziggy-js";
import {HelpStore} from "@/Stores/index.js";
import TokenProgressBar from "@/Components/TokenProgressBar.vue";
import useCurrentSubscription from "@/Utils/useCurrentSubscription.js";

const darkMode = ref(ThemeStore.isDark());
const {trialProduct} = useCurrentSubscription();
const page = usePage();

const userNavigation = [];
const portalNavigation = ref(PageNavigation().portalNavigation);


if (page.props.impersonating) {
    userNavigation.push({name: __('Stop impersonating'), href: route('portal.api.users.impersonate.stop')});
} else {
    userNavigation.push({name: __('Log Out'), href: route('logout'), method: 'post'},);
}

function currentPage(item) {
    return item.id === page.props.currentPageId;
}

function currentParentPage(item) {
    return item.children && item.children.some(subitem => subitem.id === page.props.currentPageId);
}

watch(() => page.props.enabled_facilities, () => {
    portalNavigation.value = PageNavigation().portalNavigation;
});

function generateSlug(parentLabel, childLabel = '') {
    const parentSlug = parentLabel.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    if (childLabel) {
        const childSlug = childLabel.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
        return `${parentSlug}-${childSlug}`;
    }
    return parentSlug;
}

</script>

<template>

    <div
        data-driver-id="desktop-sidebar"
        class="hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col duration-100 z-10 bg-white lg:w-72 dark:bg-slate-800">
        <div
            class="flex grow flex-col gap-y-5 border-r border-gray-200 bg-grey-200 px-6 pb-4 overflow-y-auto custom-scrollbar dark:border-slate-800">
            <div class="flex h-16 shrink-0 items-center">
                <Link :href="route('portal.dashboard')">
                        <span class="flex items-center space-x-5 text-sm shrink-0 mt-2 font-bold text-brand">
                            <ApplicationLogo/>
                        </span>
                </Link>
            </div>

            <EnvironmentBadge/>

            <nav class="flex flex-1 flex-col">
                <ul role="list" class="flex flex-1 flex-col gap-y-7"
                >
                    <li>
                        <ul role="list" class="-mx-2 space-y-1">
                            <li
                                class="relative group space-y-1"
                                v-for="(item, index) in portalNavigation"
                                :key="item.id || index"
                                :data-driver-id="`nav-${generateSlug(item.label)}`"
                            >
                                <Link :href="item.url"
                                      :title="item.label"
                                      :class="[currentPage(item) || currentParentPage(item) ? 'bg-purple-100 text-purple-600 dark:bg-purple-600 dark:text-slate-200' : 'text-gray-900 hover:text-purple-600 hover:bg-purple-50 dark:text-slate-200 dark:hover:text-purple-200 dark:hover:bg-purple-500', 'group flex gap-x-3 rounded-md p-2 leading-6']">
                                    <component v-if="item.icon" :is="item.icon"
                                               :class="[currentPage(item) || currentParentPage(item) ? 'text-purple-600 dark:text-slate-200' : 'text-gray-400 group-hover:text-purple-600 dark:group-hover:text-purple-200 dark:text-slate-400', 'h-6 w-6 shrink-0']"
                                               aria-hidden="true"/>
                                    <span>{{ item.label }}</span>
                                </Link>
                                <Submenu
                                    :item="item"
                                />
                            </li>

                            <li class="mt-5"
                                v-show="HelpStore.hasSteps.value"
                                @click="HelpStore.showHelp"
                                data-driver-id="page-help-button"
                            >
                                <div
                                    class="flex cursor-pointer text-gray-900 hover:text-purple-600 hover:bg-purple-50 dark:text-slate-200 dark:hover:text-purple-200 dark:hover:bg-purple-500 group flex gap-x-3 rounded-md p-2 leading-6">
                                    <QuestionMarkCircleIcon class="h-6 w-6 text-gray-400 dark:text-slate-400"/>
                                    <div class="text-gray-700 dark:text-white">{{ __('Help') }}</div>
                                </div>
                            </li>

                            <li class="flex py-1 pl-2 pt-5" data-driver-id="sidebar-darkmode-toggle">
                                <div
                                    class="flex flex-1 gap-x-3">
                                    <MoonIcon class="h-6 w-6 text-gray-400 dark:text-slate-400"/>
                                    <div class="text-gray-700 dark:text-white">{{ __('Dark mode') }}</div>
                                </div>
                                <Switch v-model="darkMode" @click.prevent="ThemeStore.toggle()"/>
                            </li>


                        </ul>
                    </li>
                    <li class="mt-auto">
                        <TimeCounter
                            v-if="page.props.account.on_generic_trial && !trialProduct.is_free_plan"
                            :label="__('Trial ends in:')"
                            :timezone="$page.props.timezone"
                            :start-at="$page.props.account.trial_starts_at"
                            :end-at="$page.props.account.trial_ends_at"/>

                        <TokenProgressBar :label="__('tokens available')"
                                          :value-percentage="$page.props.account.token_usage.available_credit_percent"
                                          :value="$page.props.account.token_usage.available_credit"/>

                        <div class="-mx-2 top-0 flex py-2 items-center justify-between px-2 md:py-4" data-driver-id="sidebar-profile">
                            <div class="flex items-center">
                                <Link :href="route('portal.profile.show')">
                                    <span
                                        class="hidden lg:flex lg:items-center cursor-pointer rounded-xl px-1 hover:bg-gray-50 hover:dark:bg-slate-700">
                                        <span class="flex space-x-2 text-sm font-semibold leading-6 text-gray-900"
                                              aria-hidden="true">
                                            <Avatar :user="$page.props.auth" size="w-8 h-8"/>
                                            <span class="relative mt-px">{{ $page.props.auth.nice_name }}</span>
                                        </span>
                                        <span
                                            class="ml-2">
                                            <UserInfo :user="$page.props.auth" />
                                        </span>
                                    </span>
                                </Link>
                            </div>
                            <div class="-m-1.5 flex items-center p-1.5">
                                <div
                                    class="flex space-x-2 rounded-md text-gray-400">
                                    <Link :href="route('logout')"
                                          class="hover:text-purple-600 dark:hover:text-purple-500 p-2"
                                          method="post"
                                          as="button">
                                        <ArrowRightStartOnRectangleIcon class="w-6 h-6 item-center cursor-pointer"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
