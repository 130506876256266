<script setup>
import {Label, InputError, Input} from "./";
import {nextTick, onMounted, ref, computed} from 'vue';
import {EyeSlashIcon, EyeIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import {InformationCircleIcon} from "@heroicons/vue/24/outline/index.js";

const props = defineProps({
    id: String,
    form: Object,
    label: String,
    type: {
        type: String,
        default: 'text',
    },
    required: {
        type: Boolean,
        default: false,
    },
    clearable: {
        type: Boolean,
        default: true
    },
    readonly: {
        type: Boolean,
        default: false
    },
    innerSuffix: String,
    help: String
});

defineOptions({
    inheritAttrs: false
});

const input = ref(null);
const type = ref(props.type);

const hasText = computed(() => {
  const text = props.form?.[props.id];
  return text?.length > 0;
});

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

function toggleType() {
    type.value = type.value === 'password' ? 'text' : 'password';
}

defineExpose({
    focus: async () => {
        await nextTick();
        input.value.focus()
    }
});

function clearField() {
    props.form[props.id] = '';
}

</script>

<template>
    <div :dusk="$attrs.duskComponentId">
        <div class="flex items-start space-x-2 justify-between">
            <Label
                :dusk="`${$attrs.duskComponentId}-label`"
                v-if="label" :for="id" :value="label" :required :readonly>
            </Label>
            <div v-if="help" class="text-sm">
                <VDropdown
                    :placement="'auto'"
                    :trigger="['hover']"
                >
                    <a><InformationCircleIcon class="size-6" /></a>
                    <template #popper>
                        <div class="max-w-[300px] p-5 bg-slate-900 text-white">
                            {{ help }}
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>
        <div class="flex items-center">
            <div
                class="flex mx-auto items-center flex-1 focus-within:ring-2 focus-within:ring-purple-600 read-only:focus-within:ring-0 focus-within:rounded-md relative">
                <input
                    v-bind="$attrs"
                    :id="id"
                    :type="type"
                    ref="input"
                    v-model="form[id]"
                    @change="form.clearErrors(id);form.validate(id)"
                    :readonly="readonly"
                    class="border block w-full border-gray-300 dark:border-slate-600 shadow-sm focus:ring-purple-500"
                    :class="[readonly ? 'bg-gray-50 dark:bg-slate-900 opacity-80 hover:cursor-not-allowed' : 'dark:bg-slate-700', innerSuffix ? 'rounded-l-md' : 'rounded-md']"/>

                <div
                    class="px-2 py-2 absolute top-1/2 -translate-y-1/2  flex items-center"
                    :class="props.type === 'password' ? 'right-8' : 'right-2'">
                    <XMarkIcon v-if="!readonly && clearable && form[id]" class="size-5 text-gray-400 cursor-pointer"
                               @click="clearField"/>
                </div>

                <div
                    v-if="innerSuffix"
                    class="pointer-events-none flex items-center p-2.5 bg-gray-50 border-y border-r border-gray-300 rounded-e-md">
                    <span class="text-gray-500 text-sm">{{ innerSuffix }}</span>
                </div>

                <component :is="type === 'password' ? EyeSlashIcon : EyeIcon"
                           class="size-6 absolute right-4 cursor-pointer text-gray-400"
                           v-if="props.type === 'password' && hasText"
                           @click="toggleType"/>
            </div>
        </div>

        <InputError class="mt-2" :message="form.errors[id]"/>
    </div>
</template>
