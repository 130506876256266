import {marked} from "marked";
import DOMPurify from "dompurify";

const renderer = new marked.Renderer();
renderer.link = (href, title, text) => {
    return `<a href="${href}" target="_blank" rel="noopener noreferrer"${title ? ` title="${title}"` : ''}>${text}</a>`;
};
marked.setOptions({
    renderer: renderer
});

export default function (input) {

    const cleanHtml = DOMPurify.sanitize(input, { ALLOWED_TAGS: ['img'] });

    return marked.parse(cleanHtml);
}
