import {usePage} from "@inertiajs/vue3";

export default function usePermissions() {
    const page = usePage();

    function can(groupPermission) {
        let [group, permission] = groupPermission.split(".");

        return page.props.permissions?.[group]?.[permission] || false;
    }

    return {can};
}
