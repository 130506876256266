import __ from "./translation.js";
import {route} from "ziggy-js";

export const profileTabs = [
    {
        name: __('Profile'),
        href: route('portal.profile.show')
    },
    {
        name: __('Billing'),
        href: route('portal.profile.show.billing')
    },
    {
        name: __('Affiliate'),
        href: route('portal.profile.show.affiliate')
    }
];


export const phoneNumberTabs = (phoneNumber) => [
    {
        name: __('General'),
        href: route('portal.phone-numbers.general.edit', {phone_number: phoneNumber.uuid})
    },
    {
        name: __('Zapier actions'),
        href: route('portal.phone-numbers.zapier-actions.edit', {phone_number: phoneNumber.uuid})
    }
]
