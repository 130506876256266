<script setup>
import MobileSidebar from "@/Layouts/Partials/Portal/MobileSidebar.vue";
import DesktopSidebar from "@/Layouts/Partials/Portal/DesktopSidebar.vue";
import PageHeader from "@/Layouts/Partials/Portal/PageHeader.vue";
import {router, usePage} from "@inertiajs/vue3";
import {onKeyDown} from "@vueuse/core";
import {__, createConfirmDialog} from "../Utils/index.js";
import LoginAsModal from "./Partials/LoginAsModal.vue";
import {DialogsWrapper} from 'vuejs-confirm-dialog'
import {SlideFromLeft} from "../Components/Transitions/index.js";
import {LoadingIndicator} from "../Components/Common/index.js";
import {onMounted, ref} from "vue";
import TokenUsageWarningModal from "@/Layouts/Partials/Portal/TokenUsageWarningModal.vue";
import {PageTransition} from "@/Components/Transitions/index.js";

defineProps({
    innerPadding: {
        type: Boolean,
        default: true
    }
})

const page = usePage();
const loading = ref(false);

if (page.props.auth.role === 'super_admin' || page.props.auth.isImpersonating) {
    onKeyDown('l', (event) => {
        if (event.ctrlKey) {
            event.preventDefault();
            const page = usePage();

            if (page.props.impersonating) {
                router.visit(route('portal.api.users.impersonate.stop'));
                return;
            }

            const {onConfirm} = createConfirmDialog(LoginAsModal,
                {
                    title: __('Login as'),
                });

            onConfirm((user) => {
                if (user) {
                    router.post(route('portal.api.users.impersonate'), {
                        user: user.id
                    });
                }
            });
        }
    });
}

const show = ref(false);
onMounted(async () => {
    requestAnimationFrame(() => {
        show.value = true;
    });
});

</script>

<template>
    <div class="min-h-screen bg-angular-gradient dark:bg-angular-gardient-dark">
        <DialogsWrapper/>
        <MobileSidebar/>
        <DesktopSidebar/>
        <TokenUsageWarningModal/>
        <div class="flex flex-col min-h-screen duration-100 lg:pl-72">
            <PageHeader>
                <slot name="header">
                    <div id="page-header"></div>
                </slot>
            </PageHeader>

            <main :class="{'py-2': innerPadding}">
                <div :class="{'px-2 sm:px-4 lg:px-6': innerPadding}">
                    <PageTransition>
                        <div :key="$page.url">
                            <slot />
                        </div>
                    </PageTransition>
                </div>
            </main>
        </div>
    </div>

    <SlideFromLeft>
        <div v-if="loading"
             class="fixed inset-0 bg-black/10 backdrop-blur-[1px] z-[999] flex items-center justify-center">
            <LoadingIndicator size="size-20"/>
        </div>
    </SlideFromLeft>
</template>
